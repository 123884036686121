import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import {
  AuthService,
  OAUTH_SCOPES,
  SESSION_TYPE,
  type UserTokenContext,
  type ErrorAndResultCallbackFn,
  type UserIdentityEvent,
  type UserSignInPayload,
  type UserSignOutPayload,
  type AuthorizationInfo,
} from '@vp/ubik-authfront-sdk-test' // web entry point

import { AuthContextType } from '../types'

const AuthContext = createContext<AuthContextType | null>(null)
type Config = {
  clientId?: string;
  oauthBaseURL?: string;
  access_token_claims: any;
}

// Define the callback function

interface AuthProviderProps {
  children: ReactNode;
  config: Config; // Corrected `Config` to `config` to follow prop conventions
}

const AuthProvider = ({ children, config }: AuthProviderProps) => {
  const [authInstance, setAuthInstance] = useState<AuthService | null>(null)
  const [loading, setLoading] = useState<boolean >(true)

  useEffect(() => {
    const CallBack: ErrorAndResultCallbackFn<UserIdentityEvent> = (err, res) => {
      if (err) {
        console.error('Auth Error:', err)
        return
      }
      setLoading(false)
    }
    const authConfig = {
      clientId: config.clientId,
      oauthBaseURL: config.oauthBaseURL,
      tokenAdditionalInfo: {
        expiresIn: config.access_token_claims['exp'] as number,
        accessTokenClaims: config.access_token_claims,
        sessionType: config.access_token_claims['https://claims.cimpress.io/is_anonymous'] === true ? SESSION_TYPE.ANON : SESSION_TYPE.USER,
        tokenType: 'Session',
      }
    }
    const instance = new AuthService(authConfig, CallBack)
    setAuthInstance(instance)
  }, [config]) // Added `config` as a dependency

  if (loading) return null

  return (
    <AuthContext.Provider value={{
      isSignedIn: authInstance!.isSignedIn,
      getToken: authInstance!.getToken,
      getTokenType: authInstance!.getTokenType,
      getCanonicalId: authInstance!.getCanonicalId,
      getProfile: authInstance!.getProfile,
      getIdentity: authInstance!.getIdentity,
      signIn: authInstance!.signIn,
      logout: authInstance!.logout,
      getAuthorization: authInstance!.getAuthorization,
      onUserIdentityUpdate: authInstance!.onUserIdentityUpdate,
      getRecentAnonymousIds: authInstance!.getRecentAnonymousIds,
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to use the context
const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export {
  AuthProvider,
  useAuth,
  AuthProviderProps,

  OAUTH_SCOPES,
  type Config,
  type UserSignInPayload,
  type UserSignOutPayload,
  type SESSION_TYPE,
  type UserTokenContext,
  type UserIdentityEvent,
  type AuthorizationInfo,
  type ErrorAndResultCallbackFn,

}
